<template>
  <!-- <body onload="countdown(year,month,day,hour,minute)"></body> -->
  <div id="coming-soon-page">
    <!-- fix the height of the whole content -->
    <div id="height-fix" class="text-center">
      <!--   header section begin   -->
      <section class="header bg-light-gray">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <div class="row">
                <div class="logo">
                  <img
                    class="img-responsive center-block"
                    src="assets/img/logo.png"
                  />
                </div>
                <!-- /.logo -->

                <div class="page-title">
                  <p>We will be live soon!</p>
                </div>
                <!-- /.error-description -->
              </div>
            </div>
            <!-- /.col-md-6 col-md-offset-3 -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </section>
      <!--   header section end   -->

      <!--   main-content section begin   -->
      <section class="main-content">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-md-offset-2">
              <div class="timer">
                <div class="row">
                  <div
                    class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center"
                  >
                    <div class="numbers" id="count2"></div>
                  </div>

                  <div
                    class="col-md-12 col-xs-12 col-sm-12 col-lg-12 counter1 text-center"
                    id="spacer1"
                  >
                    <div class="day numbers" id="dday"></div>
                    <div class="hr numbers" id="dhour"></div>
                    <div class="min numbers" id="dmin"></div>
                    <div class="sec numbers" id="dsec"></div>
                  </div>
                  <!-- <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 counter2 text-center" id="spacer2">
                                              <div class="row">
                                                  <div class="col-md-4 col-xs-3 col-sm-4 col-lg-4 day title" id="days">day</div>
                                                  <div class="col-md-2 col-xs-3 col-sm-2 col-lg-2 hr title" id="hours">hr</div>
                                                  <div class="col-md-2 col-xs-3 col-sm-2 col-lg-2 min title" id="minutes">min</div>
                                                  <div class="col-md-4 col-xs-3 col-sm-4 col-lg-4 sec title" id="seconds">sec</div>
                                              </div>
                                          </div> -->
                </div>
              </div>
              <!--  end of .timer  -->
            </div>
          </div>
          <!-- .row -->
        </div>
        <!-- /.container -->
      </section>
      <!--   header section end   -->

      <!--   footer section begin   -->
      <section class="footer bg-light-gray">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-md-offset-2">
              <form class="notify">
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group">
                      <input
                        name="name"
                        type="text"
                        class="form-control"
                        id="name"
                        required="required"
                        placeholder="Full Name"
                      />
                    </div>
                  </div>

                  <div class="col-md-5">
                    <div class="form-group">
                      <input
                        name="email"
                        type="email"
                        class="form-control"
                        id="email"
                        required="required"
                        placeholder="Email Address"
                      />
                    </div>
                  </div>

                  <div class="col-md-2">
                    <a href="#" class="btn btn-black"> notify me </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <div class="coming-soon-social">
                <a href="#">
                  <i class="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i class="fa fa-linkedin-square"></i>
                </a>
                <a href="#">
                  <i class="fa fa-facebook-square"></i>
                </a>
                <a href="#">
                  <i class="fa fa-skype"></i>
                </a>
                <a href="#">
                  <i class="fa fa-pinterest-square"></i>
                </a>
                <a href="#">
                  <i class="fa fa-apple"></i>
                </a>
                <a href="#">
                  <i class="fa fa-flickr"></i>
                </a>
              </div>
            </div>
            <!-- .col-md-6 col-md-offset-3 -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </section>
      <!--   footer section end   -->
    </div>
    <!-- /#height fix -->
  </div>
  <!-- /#error-page  -->
  <!-- wow initialization -->

  <!-- <script>
    new WOW().init();
  </script> -->
</template>

<script>
// import NavbarView from "@/components/Navbar.vue";
// import FooterView from "@/components/Footer.vue";

export default {
  name: "CommingSoonView",
  // components: {
  //   NavbarView,
  //   FooterView,
  // },
};
</script>
