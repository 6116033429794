<template>
  <div id="service-page">
    <!-- site-navigation start -->
    <NavbarView />
    <!-- site-navigation end -->

    <!-- header begin -->
    <header class="page-head">
      <div class="header-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <ol class="breadcrumb">
                <li><a href="index.html">Home</a></li>
                <li class="active">Service</li>
              </ol>
              <!-- end of /.breadcrumb -->
            </div>
          </div>
        </div>
        <!-- /.container -->
      </div>
      <!-- /.header-wrapper -->
    </header>
    <!-- /.page-head (header end) -->

    <div class="main-content">
      <!--  begin services section -->

      <section class="bg-white">
        <div class="container">
          <div class="headline text-center">
            <div class="row">
              <div class="col-md-6 col-md-offset-3">
                <h2 class="section-title">what we do</h2>
                <p class="section-sub-title">
                  absolutely stunning design &amp; functionality
                </p>
                <!-- /.section-sub-title -->
              </div>
            </div>
          </div>
          <!-- /.headline -->

          <div class="service-list">
            <div class="row">
              <div class="col-md-4">
                <div class="service-content text-center">
                  <div class="service-icon-box">
                    <div class="service-icon center-block">
                      <i class="fa fa-umbrella"></i>
                    </div>
                  </div>
                  <!--  end of .service-icon-box  -->
                  <div class="service-info">
                    <h3 class="service-heading">24/7 Support</h3>
                    <p class="service-description">
                      Phasellus sit amet tristique ligula. Donec iaculis leo
                      suscipit ultricies Interdum tal esuada fames ante
                      infaucibus.
                    </p>
                  </div>
                  <!--   end of .service-info  -->
                </div>
                <!--  end of .service-content  -->
              </div>

              <div class="col-md-4">
                <div class="service-content text-center">
                  <div class="service-icon-box">
                    <div class="service-icon center-block">
                      <i class="fa fa-thumbs-up"></i>
                    </div>
                  </div>
                  <!--  end of .service-icon-box  -->
                  <div class="service-info">
                    <h3 class="service-heading">Smooth and Fast</h3>
                    <p class="service-description">
                      Phasellus sit amet tristique ligula. Donec iaculis leo
                      suscipit ultricies Interdum tal esuada fames ante
                      infaucibus.
                    </p>
                  </div>
                  <!--   end of .service-info  -->
                </div>
                <!--  end of .service-content  -->
              </div>

              <div class="col-md-4">
                <div class="service-content text-center">
                  <div class="service-icon-box">
                    <div class="service-icon center-block">
                      <i class="fa fa-bug"></i>
                    </div>
                  </div>
                  <!--  end of .service-icon-box  -->
                  <div class="service-info">
                    <h3 class="service-heading">Bugs Free Code</h3>
                    <p class="service-description">
                      Phasellus sit amet tristique ligula. Donec iaculis leo
                      suscipit ultricies Interdum tal esuada fames ante
                      infaucibus.
                    </p>
                  </div>
                  <!--   end of .service-info  -->
                </div>
                <!--  end of .service-content  -->
              </div>

              <div class="col-md-4">
                <div class="service-content text-center">
                  <div class="service-icon-box">
                    <div class="service-icon center-block">
                      <i class="fa fa-cloud-download"></i>
                    </div>
                  </div>
                  <!--  end of .service-icon-box  -->
                  <div class="service-info">
                    <h3 class="service-heading">Landing Pages</h3>
                    <p class="service-description">
                      Phasellus sit amet tristique ligula. Donec iaculis leo
                      suscipit ultricies Interdum tal esuada fames ante
                      infaucibus.
                    </p>
                  </div>
                  <!--   end of .service-info  -->
                </div>
                <!--  end of .service-content  -->
              </div>

              <div class="col-md-4">
                <div class="service-content text-center">
                  <div class="service-icon-box">
                    <div class="service-icon center-block">
                      <i class="fa fa-tachometer"></i>
                    </div>
                  </div>
                  <!--  end of .service-icon-box  -->
                  <div class="service-info">
                    <h3 class="service-heading">Huge Dashboard</h3>
                    <p class="service-description">
                      Phasellus sit amet tristique ligula. Donec iaculis leo
                      suscipit ultricies Interdum tal esuada fames ante
                      infaucibus.
                    </p>
                  </div>
                  <!--   end of .service-info  -->
                </div>
                <!--  end of .service-content  -->
              </div>

              <div class="col-md-4">
                <div class="service-content text-center">
                  <div class="service-icon-box">
                    <div class="service-icon center-block">
                      <i class="fa fa-briefcase"></i>
                    </div>
                  </div>
                  <!--  end of .service-icon-box  -->
                  <div class="service-info">
                    <h3 class="service-heading">Elegant Design</h3>
                    <p class="service-description">
                      Phasellus sit amet tristique ligula. Donec iaculis leo
                      suscipit ultricies Interdum tal esuada fames ante
                      infaucibus.
                    </p>
                  </div>
                  <!--   end of .service-info  -->
                </div>
                <!--  end of .service-content  -->
              </div>
            </div>
            <!--  end of .row  -->
          </div>
          <!--  end of .service-list  -->
        </div>
        <!-- end of .container -->
      </section>
      <!--  end of services section -->

      <!-- begin pricing section -->
      <section class="bg-light-gray">
        <div class="container">
          <div class="headline text-center">
            <div class="row">
              <div class="col-md-6 col-md-offset-3">
                <h2 class="section-title">affordable pricing</h2>
                <p class="section-sub-title">
                  absolutely stunning design &amp; functionality
                </p>
                <!-- /.section-sub-title -->
              </div>
            </div>
          </div>
          <!-- /.headline -->

          <div class="row">
            <div class="col-md-3 col-sm-6">
              <div class="price-box">
                <h3>Standard</h3>
                <div class="price-info">
                  <span>15 Projects</span>
                  <span>30 GB Storage</span>
                  <span>Ultimate Data Transfer</span>
                  <span>50 GB Bandwith</span>
                  <span>Enhanced Security</span>
                  <span>24/7 Support</span>

                  <p class="price"><sup>$</sup><span>25</span>/MO</p>
                </div>
                <!-- /.price-info -->

                <a href="#" class="btn pricing-btn">Get Started</a>
              </div>
              <!-- /.price-box -->
            </div>

            <div class="col-md-3 col-sm-6">
              <div class="price-box">
                <h3>Business</h3>
                <div class="price-info">
                  <span>15 Projects</span>
                  <span>30 GB Storage</span>
                  <span>Ultimate Data Transfer</span>
                  <span>50 GB Bandwith</span>
                  <span>Enhanced Security</span>
                  <span>24/7 Support</span>

                  <p class="price"><sup>$</sup><span>35</span>/MO</p>
                </div>
                <!-- /.price-info -->

                <a href="#" class="btn pricing-btn">Get Started</a>
              </div>
              <!-- /.price-box -->
            </div>

            <div class="col-md-3 col-sm-6">
              <div class="price-box">
                <h3>Premium</h3>
                <div class="price-info">
                  <span>15 Projects</span>
                  <span>30 GB Storage</span>
                  <span>Ultimate Data Transfer</span>
                  <span>50 GB Bandwith</span>
                  <span>Enhanced Security</span>
                  <span>24/7 Support</span>

                  <p class="price"><sup>$</sup><span>45</span>/MO</p>
                </div>
                <!-- /.price-info -->

                <a href="#" class="btn pricing-btn">Get Started</a>
              </div>
              <!-- /.price-box -->
            </div>

            <div class="col-md-3 col-sm-6">
              <div class="price-box">
                <h3>Ultimate</h3>
                <div class="price-info">
                  <span>15 Projects</span>
                  <span>30 GB Storage</span>
                  <span>Ultimate Data Transfer</span>
                  <span>50 GB Bandwith</span>
                  <span>Enhanced Security</span>
                  <span>24/7 Support</span>

                  <p class="price"><sup>$</sup><span>55</span>/MO</p>
                </div>
                <!-- /.price-info -->

                <a href="#" class="btn pricing-btn">Get Started</a>
              </div>
              <!-- /.price-box -->
            </div>
          </div>
        </div>
      </section>
      <!-- end of pricing section -->

      <!--  begin testimonial section -->

      <section class="bg-light-gray">
        <div class="container">
          <div class="headline text-center">
            <div class="row">
              <div class="col-md-6 col-md-offset-3">
                <h2 class="section-title">testimonials</h2>
                <p class="section-sub-title">
                  absolutely stunning design &amp; functionality
                </p>
                <!-- /.section-sub-title -->
              </div>
            </div>
          </div>
          <!-- /.headline -->

          <div id="client-speech" class="owl-carousel owl-theme">
            <div class="item">
              <div class="row">
                <div class="col-md-6">
                  <div class="client-box">
                    <div class="about-client">
                      <img src="assets/img/client1.jpg" alt="client1" />
                      <p class="client-intro">technext-ceo</p>
                    </div>
                    <!-- end of /.about-client -->
                    <div class="main-speech">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <!-- end of /.main-speech  -->
                  </div>
                  <!-- end of /.client-box -->
                </div>

                <div class="col-md-6">
                  <div class="client-box">
                    <div class="about-client">
                      <img src="assets/img/client2.jpg" alt="client2" />
                      <p class="client-intro">technext-ceo</p>
                    </div>
                    <!-- end of /.about-client -->
                    <div class="main-speech">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that.
                      </p>
                    </div>
                    <!-- end of /.main-speech  -->
                  </div>
                  <!-- end of /.client-box -->
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-md-6">
                  <div class="client-box">
                    <div class="about-client">
                      <img src="assets/img/client1.jpg" alt="client3" />
                      <p class="client-intro">technext-ceo</p>
                    </div>
                    <!-- end of /.about-client -->
                    <div class="main-speech">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <!-- end of /.main-speech  -->
                  </div>
                  <!-- end of /.client-box -->
                </div>

                <div class="col-md-6">
                  <div class="client-box">
                    <div class="about-client">
                      <img src="assets/img/client2.jpg" alt="client4" />
                      <p class="client-intro">technext-ceo</p>
                    </div>
                    <!-- end of /.about-client -->
                    <div class="main-speech">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that.
                      </p>
                    </div>
                    <!-- end of /.main-speech  -->
                  </div>
                  <!-- end of /.client-box -->
                </div>
              </div>
            </div>

            <div class="item">
              <div class="row">
                <div class="col-md-6">
                  <div class="client-box">
                    <div class="about-client">
                      <img src="assets/img/client1.jpg" alt="client5" />
                      <p class="client-intro">technext-ceo</p>
                    </div>
                    <!-- end of /.about-client -->
                    <div class="main-speech">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                    <!-- end of /.main-speech  -->
                  </div>
                  <!-- end of /.client-box -->
                </div>

                <div class="col-md-6">
                  <div class="client-box">
                    <div class="about-client">
                      <img src="assets/img/client2.jpg" alt="client6" />
                      <p class="client-intro">technext-ceo</p>
                    </div>
                    <!-- end of /.about-client -->
                    <div class="main-speech">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that.
                      </p>
                    </div>
                    <!-- end of /.main-speech  -->
                  </div>
                  <!-- end of /.client-box -->
                </div>
              </div>
            </div>
          </div>
          <!-- end of .client-speech -->
        </div>
        <!-- end of .container -->
      </section>
      <!--  end of testimonial  section -->
    </div>
    <!--  end of .main-content  -->

    <FooterView />
  </div>
</template>

<script>
import NavbarView from "@/components/Navbar.vue";
import FooterView from "@/components/Footer.vue";

export default {
  name: "ServiceView",
  components: {
    NavbarView,
    FooterView,
  },
};
</script>
