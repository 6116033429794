<template>
  <div class="home">
    <div id="home-page">
      <!-- site-navigation start -->
      <NavbarView />
      <!-- site-navigation end -->

      <!-- header begin -->
      <HeaderHomeSection />
      <!-- /.page-head (header end) -->

      <div class="main-content">
        <IntroSection />

        <FeatureSection />

        <PortifolioSection />

        <TwitterSection />

        <PricingSection />

        <TestimonialSection />
      </div>
      <!-- end of /.main-content -->

      <FooterView />
    </div>
    <!-- end of /#home-page -->
  </div>
</template>

<script>
import NavbarView from "@/components/Navbar.vue";
import FooterView from "@/components/Footer.vue";
import HeaderHomeSection from "@/components/home/Header.vue";
import IntroSection from "@/components/home/IntroSection.vue";
import FeatureSection from "@/components/home/FeatureSection.vue";
import PortifolioSection from "@/components/home/Portifolio.vue";
import TwitterSection from "@/components/home/Twitter.vue";
import PricingSection from "@/components/home/Pricing.vue";
import TestimonialSection from "@/components/home/Testimonial.vue";

export default {
  name: "HomeView",
  components: {
    NavbarView,
    FooterView,
    HeaderHomeSection,
    IntroSection,
    FeatureSection,
    PortifolioSection,
    TwitterSection,
    PricingSection,
    TestimonialSection,
  },
};
</script>
