import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home.vue";
import About from "../views/pages/About.vue";
import ServiceView from "../views/pages/Service.vue";
import PortifolioView from "../views/pages/Portifolio.vue";
import GalleryView from "../views/pages/Gallery.vue";
import NotFoundView from "../views/pages/NotFound.vue";
import CommingSoonView from "../views/pages/CommingSoon.vue";
import ContactView from "../views/pages/Contact.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/service",
    name: "service",
    component: ServiceView,
  },
  {
    path: "/portifolio",
    name: "portifolio",
    component: PortifolioView,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
  },
  {
    path: "/404",
    name: "404",
    component: NotFoundView,
  },
  {
    path: "/commingsoon",
    name: "commingsoon",
    component: CommingSoonView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
