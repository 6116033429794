<template>
  <div class="main-content">
    <!-- begin our story section -->

    <section class="bg-white story">
      <div class="container">
        <div class="headline text-center">
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <h2 class="section-title">our story</h2>
            </div>
          </div>
        </div>
        <!-- /.headline -->

        <div class="row">
          <div class="col-md-10 col-md-offset-1 text-center">
            <p class="story-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed
              elit eu ipsum aliquam mattis ac eget tortor. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Nullam interdum at neque sed
              lobortis. Fusce hendrerit in erat a ullamcorper. Donec tincidunt
              ornare rutrum. Vestibulum quis risus vitae velit vulputate
              tristique. Donec nec tortor metus. Cum sociis natoque penatibus et
              magnis dis parturient montes, nascetur ridiculus mus. Quisque
              blandit mauris in orci iaculis, non fermentum nisl.
            </p>
            <!-- /.story-description -->
          </div>
        </div>
      </div>
    </section>
    <!-- end of our story section -->

    <!-- begin our designation section -->

    <section class="bg-white designation">
      <div class="container">
        <div class="headline text-center">
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <h2 class="section-title">our designation</h2>
            </div>
          </div>
        </div>
        <!-- /.headline -->

        <div class="team-list">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="team-member-box text-center">
                  <img
                    src="assets/img/team-member1.jpg"
                    class="img-responsive center-block"
                    alt="team-member 1"
                  />
                  <div class="team-member-description">
                    <div class="team-member-about">
                      <h4>David Martin</h4>
                      <p>
                        Phasellus sit amet tristique ligula. Donec iaculis leo
                        suscipit ultricies
                      </p>
                    </div>
                    <!-- end of /.team-member-about -->
                    <div class="team-member-social">
                      <a href="#">
                        <i class="fa fa-facebook"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-linkedin"></i>
                      </a>
                    </div>
                    <!-- end of /.team-member-social -->
                  </div>
                  <!-- end of /.team-member-description -->
                </div>
                <!-- end of /.team-member-box -->
              </div>

              <div class="col-md-4">
                <div class="team-member-box text-center">
                  <img
                    src="assets/img/team-member2.jpg"
                    class="img-responsive center-block"
                    alt="team-member 2"
                  />
                  <div class="team-member-description">
                    <div class="team-member-about">
                      <h4>Jenefir White</h4>
                      <p>
                        Phasellus sit amet tristique ligula. Donec iaculis leo
                        suscipit ultricies
                      </p>
                    </div>
                    <!-- end of /.team-member-about -->
                    <div class="team-member-social">
                      <a href="#">
                        <i class="fa fa-facebook"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-linkedin"></i>
                      </a>
                    </div>
                    <!-- end of /.team-member-social -->
                  </div>
                  <!-- end of /.team-member-description -->
                </div>
                <!-- end of /.team-member-box -->
              </div>

              <div class="col-md-4">
                <div class="team-member-box text-center">
                  <img
                    src="assets/img/team-member3.jpg"
                    class="img-responsive center-block"
                    alt="team-member 3"
                  />
                  <div class="team-member-description">
                    <div class="team-member-about">
                      <h4>Reena Scot</h4>
                      <p>
                        Phasellus sit amet tristique ligula. Donec iaculis leo
                        suscipit ultricies
                      </p>
                    </div>
                    <!-- end of /.team-member-about -->
                    <div class="team-member-social">
                      <a href="#">
                        <i class="fa fa-facebook"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-linkedin"></i>
                      </a>
                    </div>
                    <!-- end of /.team-member-social -->
                  </div>
                  <!-- end of /.team-member-description -->
                </div>
                <!-- end of /.team-member-box -->
              </div>
            </div>
          </div>
        </div>
        <!-- end of /.team-list -->
      </div>
    </section>
    <!-- end of designation section -->

    <!-- begin skill section -->

    <section class="bg-light-gray skill">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2>show off your skills</h2>
            <p>
              Integer ac metus non ipsum congue tincidunt ut id lacus. Duis
              suscipit odio non lacus aliquam, eget faucibus enim posuere.
              Aenean ut lectus ipsum. Pellentesque sit amet elementum velit. Ut
              sapien ante, suscipit a auctor in, consectetur eu nibh. Etiam
              interdum consectetur lacus ut iaculis. In luctus magna vitae
              ultricies consequat.
            </p>

            <p>
              In et blandit erat, ut gravida ante. Etiam ut est at dolor
              vehicula tempus quis ut augue. Duis nec ipsum ex. Sed in libero
              sit amet metus placerat commodo ut vitae nisi. Maecenas sit amet
              elit augue. Pellentesque pellentesque lacus eu fringilla
              dignissim. Aliquam consequat nec sapien quis tempor.
            </p>
          </div>

          <div class="col-md-6">
            <div class="skill-level">
              <p>Mobile Application</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="52"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 52%"
                >
                  52%
                </div>
              </div>

              <p>Website Design</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="98"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 98%"
                >
                  98%
                </div>
              </div>

              <p>Development</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 75%"
                >
                  75%
                </div>
              </div>

              <p>Branding</p>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 30%"
                >
                  30%
                </div>
              </div>
            </div>
            <!-- /.skill-level -->
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white sponsor">
      <div class="container">
        <div id="sponsor-list" class="owl-carousel owl-theme">
          <div class="item">
            <img
              src="assets/img/sponsor1.png"
              alt="sponsor1"
              class="center-block"
              style="width: 165px; height: 127px"
            />
          </div>

          <div class="item">
            <img
              src="assets/img/sponsor2.png"
              alt="sponsor2"
              class="center-block"
              style="width: 165px; height: 127px"
            />
          </div>

          <div class="item">
            <img
              src="assets/img/sponsor3.png"
              alt="sponsor3"
              class="center-block"
              style="width: 165px; height: 127px"
            />
          </div>

          <div class="item">
            <img
              src="assets/img/sponsor4.png"
              alt="sponsor4"
              class="center-block"
              style="width: 165px; height: 127px"
            />
          </div>

          <div class="item">
            <img
              src="assets/img/sponsor5.png"
              alt="sponsor5"
              class="center-block"
              style="width: 165px; height: 127px"
            />
          </div>

          <div class="item">
            <img
              src="assets/img/sponsor6.png"
              alt="sponsor6"
              class="center-block"
              style="width: 165px; height: 127px"
            />
          </div>
        </div>
        <!--  / #sponsor-list /.owl-carousel -->
      </div>
    </section>
  </div>
  <!-- end of /.main-content -->
</template>

<script>
export default {
  name: "AboutMainContent",
};
</script>
