<template>
  <div id="contact-page">
    <!-- site-navigation start -->
    <NavbarView />
    <!-- site-navigation end -->

    <!-- header begin -->
    <header class="page-head">
      <div class="header-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <ol class="breadcrumb">
                <li><a href="index.html">Home</a></li>
                <li class="active">Contact</li>
              </ol>
              <!-- end of /.breadcrumb -->
            </div>
          </div>
        </div>
        <!-- /.container -->
      </div>
      <!-- /.header-wrapper -->
    </header>
    <!-- /.page-head (header end) -->

    <div claas="main-content">
      <div class="container">
        <!-- google map iframe -->
        <section class="g-map">
          <div class="row">
            <div class="col-md-12">
              <div class="google-map">
                <div id="map-canvas"></div>
              </div>
            </div>
          </div>
        </section>
        <!-- /.g-map (google-map end) -->

        <!-- contact adresses section begin -->
        <section class="contact-address bg-white">
          <div class="row">
            <div class="col-md-4 col-xs-12">
              <div class="address-info">
                <div class="row">
                  <div class="col-md-3 col-xs-3">
                    <div
                      class="address-info-icon text-center center-block bg-light-gray"
                    >
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <!-- /.address-info-icon -->
                  </div>

                  <div class="col-md-9 col-xs-9 address-info-desc">
                    <h3>Office Address</h3>
                    <p>
                      Koffman Parklan, P.O Box 555 <br />
                      StreetView, USA
                    </p>
                  </div>
                  <!-- /.address-info-desc -->
                </div>
              </div>
              <!-- /.address-info -->
            </div>

            <div class="col-md-4 col-xs-12">
              <div class="address-info">
                <div class="row">
                  <div class="col-md-3 col-xs-3">
                    <div
                      class="address-info-icon text-center center-block bg-light-gray"
                    >
                      <i class="fa fa-phone"></i>
                    </div>
                    <!-- /.address-info-icon -->
                  </div>

                  <div class="col-md-9 col-xs-9 address-info-desc">
                    <h3>Phone Number</h3>
                    <p>
                      Local: 1-500-153-hello
                      <br />
                      Mobile: 1-600-153-hello
                    </p>
                  </div>
                  <!-- /.address-info-desc -->
                </div>
              </div>
              <!-- /.address-info -->
            </div>

            <div class="col-md-4 col-xs-12">
              <div class="address-info">
                <div class="row">
                  <div class="col-md-3 col-xs-3">
                    <div
                      class="address-info-icon text-center center-block bg-light-gray"
                    >
                      <i class="fa fa-envelope-o"></i>
                    </div>
                    <!-- /.address-info-icon -->
                  </div>

                  <div class="col-md-9 col-xs-9 address-info-desc">
                    <h3>Email Address</h3>
                    <p>
                      info@example.com
                      <br />
                      www.example.com
                    </p>
                  </div>
                  <!-- /.address-info-desc -->
                </div>
              </div>
              <!-- /.address-info -->
            </div>
          </div>
        </section>
        <!-- /.contact-address -->

        <!-- contact-form -->
        <section class="contact-form">
          <!-- call for contact -->
          <div class="headline text-center">
            <div class="row">
              <div class="col-md-6 col-md-offset-3">
                <h2 class="section-title">Say Hello! Don't be shy</h2>
                <p class="section-sub-title">
                  If you have any questions or comments, or would just like to
                  say hello,
                  <br />please feel free to contact our friendly team
                </p>
                <!-- /section-sub-title -->
              </div>
            </div>
          </div>
          <!-- /.contact-speech -->

          <form method="post" action="contact.php">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <input
                    name="name"
                    type="text"
                    class="form-control"
                    id="name"
                    required="required"
                    placeholder="Full Name"
                  />
                </div>

                <div class="form-group">
                  <input
                    name="email"
                    type="email"
                    class="form-control"
                    id="email"
                    required="required"
                    placeholder="Email Address"
                  />
                </div>

                <div class="form-group">
                  <input
                    name="website"
                    type="url"
                    class="form-control"
                    id="subject"
                    required="required"
                    placeholder="Your Website"
                  />
                </div>
              </div>

              <div class="col-md-8">
                <textarea
                  name="message"
                  type="text"
                  class="form-control"
                  id="message"
                  rows="8"
                  required="required"
                  placeholder="Type here message"
                ></textarea>
              </div>
            </div>

            <button
              type="submit"
              id="submit"
              name="submit"
              class="btn btn-black"
            >
              send message
            </button>
          </form>
          <!-- form end -->
        </section>
        <!-- /.contact-form -->
      </div>
      <!-- container -->
    </div>
    <!-- main-content end -->

    <FooterView />
  </div>
</template>

<script>
import NavbarView from "@/components/Navbar.vue";
import FooterView from "@/components/Footer.vue";

export default {
  name: "ContactView",
  components: {
    NavbarView,
    FooterView,
  },
};
</script>
