<template>
  <!-- begin pricing section -->
  <section class="bg-light-gray">
    <div class="container">
      <div class="headline text-center">
        <div class="row">
          <div class="col-md-6 col-md-offset-3">
            <h2 class="section-title">affordable pricing</h2>
            <p class="section-sub-title">
              absolutely stunning design &amp; functionality
            </p>
            <!-- /.section-sub-title -->
          </div>
        </div>
      </div>
      <!-- /.headline -->

      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="price-box">
            <h3>Standard</h3>
            <div class="price-info">
              <span>15 Projects</span>
              <span>30 GB Storage</span>
              <span>Ultimate Data Transfer</span>
              <span>50 GB Bandwith</span>
              <span>Enhanced Security</span>
              <span>24/7 Support</span>

              <p class="price"><sup>$</sup><span>25</span>/MO</p>
            </div>
            <!-- /.price-info -->

            <a href="#" class="btn pricing-btn">Get Started</a>
          </div>
          <!-- /.price-box -->
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="price-box">
            <h3>Business</h3>
            <div class="price-info">
              <span>15 Projects</span>
              <span>30 GB Storage</span>
              <span>Ultimate Data Transfer</span>
              <span>50 GB Bandwith</span>
              <span>Enhanced Security</span>
              <span>24/7 Support</span>

              <p class="price"><sup>$</sup><span>35</span>/MO</p>
            </div>
            <!-- /.price-info -->

            <a href="#" class="btn pricing-btn">Get Started</a>
          </div>
          <!-- /.price-box -->
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="price-box">
            <h3>Premium</h3>
            <div class="price-info">
              <span>15 Projects</span>
              <span>30 GB Storage</span>
              <span>Ultimate Data Transfer</span>
              <span>50 GB Bandwith</span>
              <span>Enhanced Security</span>
              <span>24/7 Support</span>

              <p class="price"><sup>$</sup><span>45</span>/MO</p>
            </div>
            <!-- /.price-info -->

            <a href="#" class="btn pricing-btn">Get Started</a>
          </div>
          <!-- /.price-box -->
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="price-box">
            <h3>Ultimate</h3>
            <div class="price-info">
              <span>15 Projects</span>
              <span>30 GB Storage</span>
              <span>Ultimate Data Transfer</span>
              <span>50 GB Bandwith</span>
              <span>Enhanced Security</span>
              <span>24/7 Support</span>

              <p class="price"><sup>$</sup><span>55</span>/MO</p>
            </div>
            <!-- /.price-info -->

            <a href="#" class="btn pricing-btn">Get Started</a>
          </div>
          <!-- /.price-box -->
        </div>
      </div>
    </div>
  </section>
  <!-- end of pricing section -->
</template>

<script>
export default {
  name: "PortifolioSection",
};
</script>
