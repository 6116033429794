<template>
  <!-- begin twitter-feed section -->
  <section class="twit-feed">
    <div class="twit-feed-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div id="twit" class="owl-carousel owl theme">
              <div class="item text-center">
                <div class="twit-content">
                  <div class="twit-icon">
                    <i class="fa fa-twitter"></i>
                  </div>
                  <h3 class="text-capitalize">
                    free stock photos: 74 best sites to find Awesome free images
                  </h3>
                  <p>
                    @themewagon <br />
                    2 days ago on Twitter.com
                  </p>
                </div>
              </div>

              <div class="item text-center">
                <div class="twit-content">
                  <div class="twit-icon">
                    <i class="fa fa-twitter"></i>
                  </div>
                  <h3 class="text-capitalize">
                    free stock photos: 74 best sites to find Awesome free images
                  </h3>
                  <p>
                    @themewagon <br />
                    2 days ago on Twitter.com
                  </p>
                </div>
              </div>

              <div class="item text-center">
                <div class="twit-content">
                  <div class="twit-icon">
                    <i class="fa fa-twitter"></i>
                  </div>
                  <h3 class="text-capitalize">
                    free stock photos: 74 best sites to find Awesome free images
                  </h3>
                  <p>
                    @themewagon <br />
                    2 days ago on Twitter.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--   end of /.twit-feed section  -->
</template>
<script>
export default {
  name: "TwitterSection",
};
</script>
