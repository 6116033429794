<template>
  <!--  begin testimonial section  -->

  <section class="bg-light-gray">
    <div class="container">
      <div class="headline text-center">
        <div class="row">
          <div class="col-md-6 col-md-offset-3">
            <h2 class="section-title">testimonials</h2>
            <p class="section-sub-title">
              absolutely stunning design &amp; functionality
            </p>
            <!-- /.section-sub-title -->
          </div>
        </div>
      </div>
      <!-- /.headline -->

      <div id="client-speech" class="owl-carousel owl-theme">
        <div class="item">
          <div class="row">
            <div class="col-md-6">
              <div class="client-box">
                <div class="about-client">
                  <img src="assets/img/client1.jpg" alt="client1" />
                  <p class="client-intro">technext-ceo</p>
                </div>
                <!-- end of /.about-client -->
                <div class="main-speech">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
                <!-- end of /.main-speech  -->
              </div>
              <!-- end of /.client-box -->
            </div>

            <div class="col-md-6">
              <div class="client-box">
                <div class="about-client">
                  <img src="assets/img/client2.jpg" alt="client2" />
                  <p class="client-intro">technext-ceo</p>
                </div>
                <!-- end of /.about-client -->
                <div class="main-speech">
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that.
                  </p>
                </div>
                <!-- end of /.main-speech  -->
              </div>
              <!-- end of /.client-box -->
            </div>
          </div>
        </div>

        <div class="item">
          <div class="row">
            <div class="col-md-6">
              <div class="client-box">
                <div class="about-client">
                  <img src="assets/img/client1.jpg" alt="client3" />
                  <p class="client-intro">technext-ceo</p>
                </div>
                <!-- end of /.about-client -->
                <div class="main-speech">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
                <!-- end of /.main-speech  -->
              </div>
              <!-- end of /.client-box -->
            </div>

            <div class="col-md-6">
              <div class="client-box">
                <div class="about-client">
                  <img src="assets/img/client2.jpg" alt="client4" />
                  <p class="client-intro">technext-ceo</p>
                </div>
                <!-- end of /.about-client -->
                <div class="main-speech">
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that.
                  </p>
                </div>
                <!-- end of /.main-speech  -->
              </div>
              <!-- end of /.client-box -->
            </div>
          </div>
        </div>

        <div class="item">
          <div class="row">
            <div class="col-md-6">
              <div class="client-box">
                <div class="about-client">
                  <img src="assets/img/client1.jpg" alt="client5" />
                  <p class="client-intro">technext-ceo</p>
                </div>
                <!-- end of /.about-client -->
                <div class="main-speech">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
                <!-- end of /.main-speech  -->
              </div>
              <!-- end of /.client-box -->
            </div>

            <div class="col-md-6">
              <div class="client-box">
                <div class="about-client">
                  <img src="assets/img/client2.jpg" alt="client6" />
                  <p class="client-intro">technext-ceo</p>
                </div>
                <!-- end of /.about-client -->
                <div class="main-speech">
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that.
                  </p>
                </div>
                <!-- end of /.main-speech  -->
              </div>
              <!-- end of /.client-box -->
            </div>
          </div>
        </div>
      </div>
      <!-- end of /#client-speech  /.owl-carousel -->
    </div>
    <!-- end of .container -->
  </section>
  <!--  end of testimonial  section -->
</template>
<script>
export default {
  name: "TestimonialSection",
};
</script>
