<template>
  <div id="about-page">
    <!-- site-navigation start -->
    <NavbarView />
    <!-- site-navigation end -->

    <!-- header begin -->
    <HeaderAboutSection />
    <!-- /.page-head (header end) -->

    <AboutMainContent />

    <FooterView />
  </div>
</template>

<script>
import NavbarView from "@/components/Navbar.vue";
import FooterView from "@/components/Footer.vue";
import HeaderAboutSection from "@/components/about/Header.vue";
import AboutMainContent from "@/components/about/MainContent.vue";
export default {
  name: "AboutView",
  components: {
    NavbarView,
    FooterView,
    HeaderAboutSection,
    AboutMainContent,
  },
};
</script>
