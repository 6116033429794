<template>
  <!--  begin intro section -->
  <section class="intro bg-light-gray">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <img
            src="assets/img/intro.jpg"
            class="img-responsive center-block"
            alt="intro"
          />
        </div>

        <div class="col-md-7">
          <div class="intro-description">
            <h2>avada the best agency for ever</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              ante ex, fermentum vel libero eget interdum semper libero.
              Curabitur egestas, arcu id tempor convallis.
            </p>

            <ul class="points">
              <li>
                <span>
                  <i class="fa fa-star"></i>
                </span>
                Vestibulum pulvinar commodo malesuada.
              </li>
              <li>
                <span>
                  <i class="fa fa-star"></i>
                </span>
                Pellentesque id massa et ligula convallis porta.
              </li>
              <li>
                <span>
                  <i class="fa fa-star"></i>
                </span>
                Vivamus sed nunc sed ligula rhoncus sit amet eu elit.
              </li>
              <li>
                <span>
                  <i class="fa fa-star"></i>
                </span>
                Curabitur in ipsum vel ipsum vehicula congue.
              </li>
            </ul>
            <!-- end of /.points -->

            <a href="#" class="btn btn-slategray"> purchase </a>
            <!-- /purchase button -->
          </div>
          <!-- end of /.intro-description -->
        </div>
      </div>
    </div>
    <!-- end of /.container -->
  </section>
  <!--  end of intro section -->
</template>

<script>
export default {
  name: "IntroSection",
};
</script>
