<template>
  <header class="page-head">
    <div class="header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <ol class="breadcrumb">
              <li><a href="index.html">home</a></li>
              <li class="active">about us</li>
            </ol>
            <!-- end of /.breadcrumb -->
          </div>
        </div>
      </div>
      <!-- /.container -->
    </div>
    <!-- /.header-wrapper -->
  </header>
</template>

<script>
export default {
  name: "HeaderAboutSection",
};
</script>
