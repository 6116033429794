<template>
  <nav
    id="mainNavigation"
    class="navbar navbar-default main-navigation"
    role="navigation"
  >
    <div class="container">
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#main-nav-collapse"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <!-- navbar logo -->
        <div class="navbar-brand">
          <span class="sr-only">Avada Pro Agency</span>
          <a href="index.html">
            <img
              src="assets/img/main_logo.png"
              class="img-responsive center-block"
              alt="logo"
            />
          </a>
        </div>
        <!-- navbar logo -->
      </div>
      <!-- /.navbar-header -->

      <!-- nav links -->
      <div class="collapse navbar-collapse" id="main-nav-collapse">
        <ul class="nav navbar-nav navbar-right text-uppercase">
          <li>
            <router-link to="/">home</router-link>
          </li>

          <li class="dropdown">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown"
              ><span>pages</span></a
            >
            <ul class="dropdown-menu">
              <li>
                <router-link to="/about">About</router-link>
              </li>
              <li>
                <router-link to="/service">Service</router-link>
              </li>
              <li>
                <router-link to="/portifolio">Portifolio</router-link>
              </li>
              <li>
                <router-link to="/gallery">Gallery</router-link>
              </li>
              <li>
                <router-link to="/404">404 page</router-link>
              </li>
              <li>
                <router-link to="/commingsoon">coming soon</router-link>
              </li>
            </ul>
            <!-- end of /.dropdown-menu -->
          </li>
          <!-- end of /.dropdown -->

          <li class="dropdown">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown"
              ><span>blog</span></a
            >
            <ul class="dropdown-menu">
              <li>
                <a href="multiple_blog_left_sidebar.html"
                  >multiple with left sidebar</a
                >
              </li>
              <li>
                <a href="multiple_blog.html">multiple with right sidebar</a>
              </li>
              <li>
                <a href="single_blog_left_sidebar.html"
                  >single with left sidebar</a
                >
              </li>
              <li>
                <a href="single_blog.html">single with right sidebar</a>
              </li>
              <li>
                <a href="single_blog_full_width.html">single full width</a>
              </li>
            </ul>
            <!-- end of /.dropdown-menu -->
          </li>
          <!-- end of /.dropdown -->

          <li>
            <router-link to="/contact">contact</router-link>
          </li>
        </ul>
      </div>
      <!-- nav links -->
    </div>
    <!-- /.container -->
  </nav>
</template>

<script>
export default {
  name: "NavbarView",
};
</script>
