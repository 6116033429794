<template>
  <div id="portfolio-page">
    <!-- site-navigation start -->
    <NavbarView />
    <!-- site-navigation end -->

    <!-- header begin -->
    <header class="page-head">
      <div class="header-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <ol class="breadcrumb">
                <li><a href="index.html">Home</a></li>
                <li class="active">Portfolio</li>
              </ol>
              <!-- end of /.breadcrumb -->
            </div>
          </div>
        </div>
        <!-- /.container -->
      </div>
      <!-- /.header-wrapper -->
    </header>
    <!-- /.page-head (header end) -->

    <div class="main-content">
      <!--  begin portfolio section  -->
      <section class="bg-light-gray">
        <div class="container">
          <div class="headline text-center">
            <div class="row">
              <div class="col-md-6 col-md-offset-3">
                <h2 class="section-title">show off our portfolio</h2>
                <p class="section-sub-title">
                  absolutely stunning design &amp; functionality
                </p>
                <!-- /.section-sub-title -->
              </div>
            </div>
          </div>
          <!-- /.headline -->

          <div class="portfolio-item-list">
            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="portfolio-item">
                  <div class="item-image">
                    <a href="#">
                      <img
                        src="assets/img/portfolio1.jpg"
                        class="img-responsive center-block"
                        alt="portfolio 1"
                      />
                      <div>
                        <span><i class="fa fa-plus"></i></span>
                      </div>
                    </a>
                  </div>

                  <div class="item-description">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="item-name"> Oliver a Note </span>
                        <span> Fashion </span>
                      </div>
                      <div class="col-xs-6">
                        <span class="like">
                          <i class="fa fa-heart-o"></i>
                          576
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end of /.item-description -->
                </div>
                <!-- end of /.portfolio-item -->
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="portfolio-item">
                  <div class="item-image">
                    <a href="#">
                      <img
                        src="assets/img/portfolio2.jpg"
                        class="img-responsive center-block"
                        alt="portfolio 2"
                      />
                      <div>
                        <span><i class="fa fa-plus"></i></span>
                      </div>
                    </a>
                  </div>

                  <div class="item-description">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="item-name"> Shift Magazine </span>
                        <span> Photography </span>
                      </div>
                      <div class="col-xs-6">
                        <span class="like">
                          <i class="fa fa-heart-o"></i>
                          576
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end of /.item-description -->
                </div>
                <!-- end of /.portfolio-item -->
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="portfolio-item">
                  <div class="item-image">
                    <a href="#">
                      <img
                        src="assets/img/portfolio3.jpg"
                        class="img-responsive center-block"
                        alt="portfolio 3"
                      />
                      <div>
                        <span><i class="fa fa-plus"></i></span>
                      </div>
                    </a>
                  </div>

                  <div class="item-description">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="item-name"> Camp Pine Tree </span>
                        <span> Web Design </span>
                      </div>
                      <div class="col-xs-6">
                        <span class="like">
                          <i class="fa fa-heart-o"></i>
                          576
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end of /.item-description -->
                </div>
                <!-- end of /.portfolio-item -->
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="portfolio-item">
                  <div class="item-image">
                    <a href="#">
                      <img
                        src="assets/img/portfolio4.jpg"
                        class="img-responsive center-block"
                        alt="portfolio 4"
                      />
                      <div>
                        <span><i class="fa fa-plus"></i></span>
                      </div>
                    </a>
                  </div>

                  <div class="item-description">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="item-name"> Free Art App Design </span>
                        <span> Photography </span>
                      </div>
                      <div class="col-xs-6">
                        <span class="like">
                          <i class="fa fa-heart-o"></i>
                          576
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end of /.item-description -->
                </div>
                <!-- end of /.portfolio-item -->
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="portfolio-item">
                  <div class="item-image">
                    <a href="#">
                      <img
                        src="assets/img/portfolio5.jpg"
                        class="img-responsive center-block"
                        alt="portfolio 5"
                      />
                      <div>
                        <span><i class="fa fa-plus"></i></span>
                      </div>
                    </a>
                  </div>

                  <div class="item-description">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="item-name"> Large Poster Set </span>
                        <span> Marketing </span>
                      </div>
                      <div class="col-xs-6">
                        <span class="like">
                          <i class="fa fa-heart-o"></i>
                          576
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end of /.item-description -->
                </div>
                <!-- end of /.portfolio-item -->
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="portfolio-item">
                  <div class="item-image">
                    <a href="#">
                      <img
                        src="assets/img/portfolio6.jpg"
                        class="img-responsive center-block"
                        alt="portfolio 6"
                      />
                      <div>
                        <span><i class="fa fa-plus"></i></span>
                      </div>
                    </a>
                  </div>

                  <div class="item-description">
                    <div class="row">
                      <div class="col-xs-6">
                        <span class="item-name"> Skilet Album Cover </span>
                        <span> Business </span>
                      </div>
                      <div class="col-xs-6">
                        <span class="like">
                          <i class="fa fa-heart-o"></i>
                          576
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- end of /.item-description -->
                </div>
                <!-- end of /.portfolio-item -->
              </div>
            </div>
          </div>
          <!-- end of portfolio-item-list -->

          <div id="morePortfolio"></div>
          <div class="text-center">
            <a id="loadPortfolio" class="hidden-xs btn btn-white">
              Load More <span class="caret"></span>
            </a>
          </div>
        </div>
      </section>
      <!--   end of portfolio section  -->
    </div>
    <!-- end of /.main-content -->

    <FooterView />
  </div>
</template>

<script>
import NavbarView from "@/components/Navbar.vue";
import FooterView from "@/components/Footer.vue";

export default {
  name: "PortifolioView",
  components: {
    NavbarView,
    FooterView,
  },
};
</script>
