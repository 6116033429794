<template>
  <div id="gallery-page">
    <!-- site-navigation start -->
    <NavbarView />
    <!-- site-navigation end -->

    <!-- header begin -->
    <header class="page-head">
      <div class="header-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <ol class="breadcrumb">
                <li><a href="index.html">Home</a></li>
                <li class="active">gallery</li>
              </ol>
              <!-- end of /.breadcrumb -->
            </div>
          </div>
        </div>
        <!-- /.container -->
      </div>
      <!-- /.header-wrapper -->
    </header>
    <!-- /.page-head (header end) -->

    <section class="gallery">
      <div class="container">
        <div class="headline text-center">
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <h2 class="section-title">our gallery</h2>
              <p class="section-sub-title">
                absolutely stunning design &amp; functionality
              </p>
              <!-- /.section-sub-title -->
            </div>
          </div>
        </div>
        <!-- /.headline -->

        <ul id="galleryContent">
          <li class="gallery-item">
            <img src="assets/img/showcase1.jpg" alt="showcase1" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase1.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase2.jpg" alt="showcase2" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase2.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase3.jpg" alt="showcase3" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase3.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase4.jpg" alt="showcase4" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase4.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase5.jpg" alt="showcase5" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase5.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase4.jpg" alt="showcase6" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase4.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase7.jpg" alt="showcase7" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase7.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase8.jpg" alt="showcase8" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase8.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase6.jpg" alt="showcase9" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase6.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase7.jpg" alt="showcase10" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase7.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase3.jpg" alt="showcase11" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase3.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase1.jpg" alt="showcase12" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase1.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase2.jpg" alt="showcase13" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase2.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase3.jpg" alt="showcase14" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase3.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase4.jpg" alt="showcase15" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase4.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase8.jpg" alt="showcase16" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase8.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase5.jpg" alt="showcase17" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase5.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase6.jpg" alt="showcase18" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase6.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <li class="gallery-item">
            <img src="assets/img/showcase7.jpg" alt="showcase19" />
            <div class="gallery-item-zoom">
              <a
                class="item-zoom"
                href="assets/img/showcase7.jpg"
                data-lightbox-gallery="gallery"
              >
                <i class="fa fa-search-plus"></i>
              </a>
            </div>
          </li>

          <div id="moreGallery"></div>
          <div class="text-center">
            <a id="loadGallery" class="hidden-xs btn btn-white">
              Load More <span class="caret"></span>
            </a>
          </div>
        </ul>
        <!-- /#galleryContent -->
      </div>
    </section>

    <FooterView />
  </div>
</template>

<script>
import NavbarView from "@/components/Navbar.vue";
import FooterView from "@/components/Footer.vue";

export default {
  name: "GalleryView",
  components: {
    NavbarView,
    FooterView,
  },
};
</script>
