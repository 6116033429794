<template>
  <!--  begin feature section  -->
  <section class="bg-white feature">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="feature-content text-center">
            <div class="feature-icon-box">
              <div class="feature-icon center-block">
                <i class="fa fa-laptop"></i>
              </div>
            </div>
            <!--  end of /.feature-icon-box  -->
            <div class="feature-info">
              <h3 class="feature-heading">Responsive &amp; Multi-purpose</h3>
              <p class="feature-description">
                Class aptent taciti sociosqu ad litora torquent conubia nostra,
                per inceptos himenaeos.
              </p>
              <!--   end of /.feature-description  -->
            </div>
            <!--   end of /.feature-info  -->
          </div>
          <!--  end of /.feature-content  -->
        </div>

        <div class="col-md-3">
          <div class="feature-content text-center">
            <div class="feature-icon-box">
              <div class="feature-icon center-block">
                <i class="fa fa-eye"></i>
              </div>
            </div>
            <!--  end of /.feature-icon-box  -->
            <div class="feature-info">
              <h3 class="feature-heading">Clean &amp; Clear</h3>
              <p class="feature-description">
                Class aptent taciti sociosqu ad litora torquent conubia nostra,
                per inceptos himenaeos.
              </p>
              <!--   end of /.feature-description  -->
            </div>
            <!--   end of /.feature-info  -->
          </div>
          <!--  end of /.feature-content  -->
        </div>

        <div class="col-md-3">
          <div class="feature-content text-center">
            <div class="feature-icon-box">
              <div class="feature-icon center-block">
                <i class="fa fa-thumbs-o-up"></i>
              </div>
            </div>
            <!--  end of /.feature-icon-box  -->
            <div class="feature-info">
              <h3 class="feature-heading">Best UX</h3>
              <p class="feature-description">
                Class aptent taciti sociosqu ad litora torquent conubia nostra,
                per inceptos himenaeos.
              </p>
              <!--   end of /.feature-description  -->
            </div>
            <!--   end of /.feature-info  -->
          </div>
          <!--  end of /.feature-content  -->
        </div>

        <div class="col-md-3">
          <div class="feature-content text-center">
            <div class="feature-icon-box">
              <div class="feature-icon center-block">
                <i class="fa fa-star"></i>
              </div>
            </div>
            <!--  end of /.feature-icon-box  -->
            <div class="feature-info">
              <h3 class="feature-heading">Good Feedback</h3>
              <p class="feature-description">
                Class aptent taciti sociosqu ad litora torquent conubia nostra,
                per inceptos himenaeos.
              </p>
              <!--   end of /.feature-description  -->
            </div>
            <!--   end of /.feature-info  -->
          </div>
          <!--  end of /.feature-content  -->
        </div>
      </div>
    </div>
    <!-- end of /.container -->
  </section>
  <!--   end of feature section  -->
</template>

<script>
export default {
  name: "FeatureSection",
};
</script>
